@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
*{
    font-family: 'Poppins', sans-serif;
}
.App{
    background:#111;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.countdown-timer {
  margin-top: 0px;
  display: grid;
  place-items: center;
 
}
img{
    margin-bottom: 50px;
}

h1 {
  @apply text-3xl font-bold !important;
  display: flex;
  justify-content: center;
  color: #2296f3;
  margin-bottom: 5%;
  margin-top: 20px;
}

.countdown {
  @apply flex justify-center;
  margin-top: 20px;
  color: white;
}

.time-block {
  @apply m-0 mx-2;
  color: red;
  margin-top: 30px;
}

.time {
  @apply text-4xl font-bold;
  color: white;
  font-size: 30px;
  margin-bottom: 10px;
}

.label {
  @apply text-sm;
  color: white;
  font-size: 15px;
  margin-bottom: 20px;
}
.animm{
  margin-bottom: 20px;
}
